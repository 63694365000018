/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
.awardable-actions-wrapper {
  display: none;
  opacity: 0;
}
.awardable-actions-wrapper.visible {
  display: block;
  box-sizing: border-box;
  position: fixed;
  height: 80px;
  width: 80px;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 50%;
  top: 70px;
  right: 20px;
  z-index: 33;
  color: #fff;
  animation-name: spring-popup;
  animation-duration: 1.5s;
  animation-timing-function: linear;
}
@media screen and (max-width: 47.99em) {
  .awardable-actions-wrapper.visible {
    top: 95px;
    right: 20px;
  }
}
.awardable-actions-wrapper.visible p {
  text-align: center;
  margin-bottom: 0;
  line-height: 1;
}
.awardable-actions-wrapper.visible p.points-plus-text {
  font-size: 1.25em;
  font-weight: bold;
}
.awardable-actions-wrapper.visible p.points-text {
  font-size: 0.75em;
}
.awardable-actions-wrapper.visible p:first-child {
  margin-top: 27px;
}

@keyframes spring-popup {
  0% {
    transform: scale(0) translateY(50%);
    opacity: 0;
  }
  0.714285% {
    transform: scale(0.09504) translateY(45.2482%);
    opacity: 0.09504;
  }
  1.4285725% {
    transform: scale(0.20569) translateY(39.71542%);
    opacity: 0.20569;
  }
  2.1428575% {
    transform: scale(0.32234) translateY(33.88325%);
    opacity: 0.32234;
  }
  2.8571425% {
    transform: scale(0.43778) translateY(28.11084%);
    opacity: 0.43778;
  }
  3.5714275% {
    transform: scale(0.54695) translateY(22.65273%);
    opacity: 0.54695;
  }
  4.285715% {
    transform: scale(0.64647) translateY(17.67651%);
    opacity: 0.64647;
  }
  5% {
    transform: scale(0.73441) translateY(13.2796%);
    opacity: 0.73441;
  }
  5.714285% {
    transform: scale(0.80991) translateY(9.50458%);
    opacity: 0.80991;
  }
  6.4285725% {
    transform: scale(0.87295) translateY(6.35268%);
    opacity: 0.87295;
  }
  7.1428575% {
    transform: scale(0.92409) translateY(3.79551%);
    opacity: 0.92409;
  }
  7.8571425% {
    transform: scale(0.96431) translateY(1.78473%);
    opacity: 0.96431;
  }
  8.5714275% {
    transform: scale(0.9948) translateY(0.26002%);
    opacity: 0.9948;
  }
  9.285715% {
    transform: scale(1.01689) translateY(-0.84471%);
    opacity: 1.01689;
  }
  10% {
    transform: scale(1.03193) translateY(-1.59654%);
    opacity: 1.03193;
  }
  10.714285% {
    transform: scale(1.0412) translateY(-2.06017%);
    opacity: 1.0412;
  }
  11.4285725% {
    transform: scale(1.04591) translateY(-2.29548%);
    opacity: 1.04591;
  }
  12.1428575% {
    transform: scale(1.04712) translateY(-2.35613%);
    opacity: 1.04712;
  }
  12.8571425% {
    transform: scale(1.04578) translateY(-2.28878%);
    opacity: 1.04578;
  }
  13.5714275% {
    transform: scale(1.04266) translateY(-2.13288%);
    opacity: 1.04266;
  }
  14.285715% {
    transform: scale(1.03842) translateY(-1.92087%);
    opacity: 1.03842;
  }
  15% {
    transform: scale(1.03357) translateY(-1.67862%);
    opacity: 1.03357;
  }
  15.714285% {
    transform: scale(1.02852) translateY(-1.4261%);
    opacity: 1.02852;
  }
  16.4285725% {
    transform: scale(1.02356) translateY(-1.17809%);
    opacity: 1.02356;
  }
  17.1428575% {
    transform: scale(1.0189) translateY(-0.94498%);
    opacity: 1.0189;
  }
  17.5714275% {
    transform: scale(1.01467) translateY(-0.73351%);
    opacity: 1.01467;
  }
  18.5714275% {
    transform: scale(1.01095) translateY(-0.54749%);
    opacity: 1.01095;
  }
  19.285715% {
    transform: scale(1.00777) translateY(-0.38845%);
    opacity: 1.00777;
  }
  20% {
    transform: scale(1.00512) translateY(-0.25623%);
    opacity: 1.00512;
  }
  20.714285% {
    transform: scale(1.00299) translateY(-0.14944%);
    opacity: 1.00299;
  }
  21.4285725% {
    transform: scale(1.00132) translateY(-0.06589%);
    opacity: 1.00132;
  }
  22.1428575% {
    transform: scale(1.00006) translateY(-0.00293%);
    opacity: 1.00006;
  }
  22.5714275% {
    transform: scale(0.99915) translateY(0.04232%);
    opacity: 0.99915;
  }
  23.5714275% {
    transform: scale(0.99854) translateY(0.07275%);
    opacity: 0.99854;
  }
  24.285715% {
    transform: scale(0.99818) translateY(0.09114%);
    opacity: 0.99818;
  }
  25%, 95% {
    transform: scale(1) translateY(0%);
    opacity: 1;
  }
  100% {
    transform: scale(1) translateY(0%);
    opacity: 0;
  }
}
