/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
.language-selection-dialog .button {
  margin-bottom: 0;
}
.language-selection-dialog .button:not(:first-child) {
  margin-left: 10px;
}
