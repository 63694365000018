/*------------------------------------*\
    # COLORS
\*------------------------------------*/

/**
 * Defaults
 */

$aspire-blue: #24549D;
$aspire-blue-light: #298CBC;

$aspire-gray: #3B3B35;

/**
 * Black, white & gray
 */

$black: #000;

/**
 * Main interface colors
 */

$cta: $aspire-blue-light;

$link-color: $aspire-blue;
