@font-face {
  font-family: "gamicons";
  src: url("../../fonts/core/gamicons/gamicons.eot");
  src: url("../../fonts/core/gamicons/gamicons.eot?#iefix") format("embedded-opentype"), url("../../fonts/core/gamicons/gamicons.woff") format("woff"), url("../../fonts/core/gamicons/gamicons.ttf") format("truetype"), url("../../fonts/core/gamicons/gamicons.svg#gamicons") format("svg");
  font-weight: normal;
  font-style: normal;
}
.gamicons {
  font-family: "gamicons" !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  text-transform: none;
  speak: none;
  line-height: 1;
  font-weight: 900;
}

.icon-topics:before {
  content: "x";
  font-size: 1.25em;
}

.icon-challenge-placeholder:before {
  content: "y";
}

/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/*------------------------------------*\
    # STRUCTURE
\*------------------------------------*/
/**
 * Margins and paddings
 */
/**
 * Mobile breakpoints:
 * These indicate the minimum width for each breakpoint.
 * If you intend to change the breakpoints, you should
 * change the minimum/maximum widths assigned to the
 * breakpoints in pure/css/grids-responsive.css too.
 * Approx 16px per em.
 */
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/*------------------------------------*\
    # ICONS
\*------------------------------------*/
/**
 * Sizes
 */
/**
 * Colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # SIDEBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # BUTTONS
\*------------------------------------*/
/**
 * Margins & Padding
 */
/**
 * Fonts
 */
/**
 * Sizes
 */
/**
 * Specific Buttons
 */
/*------------------------------------*\
    # MODALS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Borders
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # DIALOGS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/**
 * Typography
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # PROGRESS BAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/*------------------------------------*\
    # ALERTS
\*------------------------------------*/
/**
 * Sizes
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # LOGIN & REGISTER PAGES
\*------------------------------------*/
/**
 * Colors (Buttons)
 */
/*------------------------------------*\
    # PROJECT PAGE
\*------------------------------------*/
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # FLOATING BUTTONS
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/**
 * Sizes
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # TOPBAR
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARDS
\*------------------------------------*/
/**
 * Sizes (CTA Buttons)
 */
/**
 * Margins and paddings
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # COLORS
\*------------------------------------*/
/**
 * Defaults
 */
/**
 * Black, white & gray
 */
/**
 * Main interface colors
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # TOPIC CARD SLIDER
\*------------------------------------*/
/**
 * Background
 */
/*------------------------------------*\
    # MOBILE PROJECT PAGE -
    # PROJECT TITLE CARD
\*------------------------------------*/
/**
 * Colors
 */
.badge {
  padding: 2px 3px;
  font-size: 0.65em;
  line-height: 1;
  border-radius: 3px;
}

.notification-icon {
  background-image: url("../../../images/core/notification_icon.png");
  width: 16px;
  height: 18px;
  display: inline-block;
  background-position: 0 -18px;
}
.darkicons .notification-icon {
  background-position: 0 0;
}
.lighticons .notification-icon {
  background-position: 0 -18px;
}

.right-caret {
  background-image: url("../../../images/core/claim_caret.png");
  width: 12px;
  height: 18px;
  background-size: contain;
  display: inline-block;
}

/* navigation icons */
/* note: $topbar-icon-style is used for scss, dark/lighticons is for customizr */
.nav-icon, .settings-icon, .logout-icon, .bookmarks-icon, .menu-icon, .language-icon, .search-icon, .teams-icon, .rewards-icon, .achievements-icon, .leaderboard-icon, .activity-icon, .home-icon {
  background-image: url("../../../images/core/navigation_sprite.png");
  width: 26px;
  height: 26px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.home-icon {
  background-position: 0 -26px;
}
.darkicons .home-icon {
  background-position: 0 0;
}
.lighticons .home-icon {
  background-position: 0 -26px;
}

.activity-icon {
  background-position: -26px -26px;
}
.darkicons .activity-icon {
  background-position: -26px 0;
}
.lighticons .activity-icon {
  background-position: -26px -26px;
}

.leaderboard-icon {
  background-position: -52px -26px;
}
.darkicons .leaderboard-icon {
  background-position: -52px 0;
}
.lighticons .leaderboard-icon {
  background-position: -52px -26px;
}

.achievements-icon {
  background-position: -78px -26px;
}
.darkicons .achievements-icon {
  background-position: -78px 0;
}
.lighticons .achievements-icon {
  background-position: -78px -26px;
}

.rewards-icon {
  background-position: -104px -26px;
}
.darkicons .rewards-icon {
  background-position: -104px 0;
}
.lighticons .rewards-icon {
  background-position: -104px -26px;
}

.teams-icon {
  background-position: -156px -26px;
}
.darkicons .teams-icon {
  background-position: -156px 0;
}
.lighticons .teams-icon {
  background-position: -156px -26px;
}

.search-icon {
  background-position: -182px -26px;
}
.search-icon.active {
  background-position: -182px 0;
}
.darkicons .search-icon {
  background-position: -182px 0;
}
.lighticons .search-icon {
  background-position: -182px -26px;
}

.language-icon {
  width: 50px;
  height: 30px;
  background-image: none;
  position: relative;
  top: 2px;
  overflow: hidden; /* restricts clickable space */
  color: #fff;
}
.language-icon i {
  position: relative;
  font-size: 24px;
  top: 0;
  display: block;
}
.darkicons .language-icon {
  color: #000;
}
.lighticons .language-icon {
  color: #fff;
}

.menu-icon {
  background-position: -208px -26px;
}
.darkicons .menu-icon {
  background-position: -208px 0;
}
.lighticons .menu-icon {
  background-position: -208px -26px;
}

.bookmarks-icon {
  background-position: -234px -26px;
}
.darkicons .bookmarks-icon {
  background-position: -234px 0;
}
.lighticons .bookmarks-icon {
  background-position: -234px -26px;
}

.logout-icon {
  background-position: -260px -26px;
}
.darkicons .logout-icon {
  background-position: -260px 0;
}
.lighticons .logout-icon {
  background-position: -260px -26px;
}

.settings-icon {
  background-position: -286px -26px;
}
.darkicons .settings-icon {
  background-position: -286px 0;
}
.lighticons .settings-icon {
  background-position: -286px -26px;
}

/* challenge icons */
.challengeicon {
  display: inline-block;
  vertical-align: bottom;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background: black;
  margin: 0 1.5px;
}
.challengeicon .points {
  margin-top: 9px;
  text-align: center;
  font-size: 13px;
  color: white;
}
.challengeicon.icon {
  font-family: "gamicons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 37px;
  background: transparent;
  position: relative;
}
.challengeicon.icon:before {
  position: relative;
  z-index: 1;
}
.challengeicon.icon:after {
  content: "t";
  color: #fff;
  position: absolute;
  /*
    Awkward offset of 1px for positioning,
    and 2px for font-size to remove white border
  */
  top: 1px;
  left: 1px;
  font-size: 35px;
}
.challengeicon.icon.normal {
  color: #36afe6;
}
.challengeicon.icon.normal:before {
  content: "h";
}
.challengeicon.icon.photo {
  color: #fb970b;
}
.challengeicon.icon.photo:before {
  content: "e";
}
.challengeicon.icon.quiz {
  color: #aa4fb8;
}
.challengeicon.icon.quiz:before {
  content: "r";
}
.challengeicon.icon.prediction {
  color: #9ccc28;
}
.challengeicon.icon.prediction:before {
  content: "o";
}
.challengeicon.icon.non-interactive {
  color: #1fba7a;
}
.challengeicon.icon.non-interactive:before {
  content: "c";
}
.challengeicon.icon.qr {
  color: #eb424b;
}
.challengeicon.icon.qr:before {
  content: "p";
}
.challengeicon.icon.key {
  color: #efd608;
}
.challengeicon.icon.key:before {
  content: "d";
}
.challengeicon.icon.quiz-repeat {
  color: #aa4fb8;
}
.challengeicon.icon.quiz-repeat:before {
  content: "q";
}
.challengeicon.icon.survey {
  color: #d60d7f;
}
.challengeicon.icon.survey:before {
  content: "f";
}
.challengeicon.icon.survey-single {
  color: #d60d7f;
}
.challengeicon.icon.survey-single:before {
  content: "n";
}
.challengeicon.icon.survey-multi {
  color: #d60d7f;
}
.challengeicon.icon.survey-multi:before {
  content: "g";
}
.challengeicon.icon.multi-field {
  color: #059fa9;
}
.challengeicon.icon.multi-field:before {
  content: "b";
}
.challengeicon.icon.personality-quiz {
  color: #aa4fb8;
}
.challengeicon.icon.personality-quiz:before {
  content: "u";
}
.challengeicon.icon.confirmation {
  color: #234d8c;
}
.challengeicon.icon.confirmation:before {
  content: "a";
}
.challengeicon.icon.video {
  color: #ce2951;
}
.challengeicon.icon.video:before {
  content: "i";
}
.challengeicon.icon.external {
  color: #b1bc31;
}
.challengeicon.icon.external:before {
  content: "v";
}
.challengeicon.quiz-result {
  background: url("../../../images/core/challenge/quiz_result_sprite.png");
}
.challengeicon.quiz-result.right {
  background-position: 0 0;
}
.challengeicon.quiz-result.wrong {
  background-position: -37px 0;
}

.like-icon {
  display: block;
  background-image: url("../../../images/core/challenge/icon_like.png");
  width: 20px;
  height: 20px;
  margin: auto;
}
.liked .like-icon {
  background-position: -20px 0;
}

.more-icon {
  display: block;
  background-image: url("../../../images/core/challenge/icon_more.png");
  background-size: contain;
  width: 20px;
  height: 18px;
  margin: auto;
}

.delete-icon {
  display: block;
  background-image: url("../../../images/core/challenge/icon_delete.png");
  background-size: contain;
  width: 20px;
  height: 18px;
  margin: auto;
}

.bookmark-icon {
  display: block;
  background-image: url("../../../images/core/challenge/icon_bookmark.png");
  width: 20px;
  height: 20px;
  margin: auto;
}
.bookmarked .bookmark-icon {
  background-position: -20px 0;
}

.comment-icon {
  display: block;
  background-image: url("../../../images/core/challenge/icon_comment.png");
  background-size: contain;
  width: 20px;
  height: 18px;
  margin: auto;
}

.expand-icon {
  display: block;
  background-image: url("../../../images/core/challenge/icon_expand.png");
  background-size: contain;
  width: 20px;
  height: 18px;
  margin: auto;
}

.language-icon {
  width: 42px;
  text-align: center;
}
.language-icon .language-badge {
  position: absolute;
  background: #24549D;
  display: inline-block;
  font-size: 0.55em;
  font-weight: 700;
  line-height: 1;
  border: 1px solid #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  padding: 0.45em 0.45em 0.3em;
  top: 13px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
